/* You can add global styles to this file, and also import other style files */
body, div, table, th, tr, input, textarea, button, select{
  font-family: inherit;
}

// body.local
.local {
  margin: 0;
}

app-lsw-search {
  font-size: 16px;
  font-family: Roboto, sans-serif;
}

.lsw-tabs .mat-tab-label img {
  max-width: initial !important;
}

.lsw-tooltip {
  background-color: #021A35 !important;
  font-family: Roboto, sans-serif;
  text-align: left !important;
  line-height: 16px !important;
  font-size: 14px !important;
}